<template>
  <div class="wrapper">
    <swiper :options="swiperOption" class="swiper-container">
      <!-- slides -->
      <swiper-slide
        class="swiper-item"
        v-for="item of swiperList"
        :key="item.id"
      >
        <img class="swiper-img" :src="item.ImagePath" />
      </swiper-slide>
      <!-- Optional controls ,显示小点-->
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.css";

import { swiper, swiperSlide } from "vue-awesome-swiper";
// import { get } from "../../plugins/axios";
export default {
  components: { swiper, swiperSlide },
  data() {
    return {
      swiperOption: {
        // 参数选项,显示小点
        pagination: ".swiper-pagination",
        //循环
        loop: true,
        //每张播放时长3秒，自动播放
        autoplay: 4000,
        //滑动速度
        speed: 1000,
        // delay:1000
      },
      //三张轮播，使用变量循环
      swiperList: [
        // {
        //   id: "003",
        //   ImagePath:
        //     "../assets/Banner/jysx.jpg",
        // },
        // {
        //   id: "004",
        //   ImagePath:
        //     "../assets/Banner/tygsx.jpg",
        // },
        // {
        //   id: "005",
        //   ImagePath:
        //     "../assets/Banner/dgnsx.jpg",
        // },
        {
          id: "003",
          ImagePath:
            "../assets/Banner/banner1.jpg",
        },
        {
          id: "004",
          ImagePath:
            "../assets/Banner/banner2.jpg",
        },
      ],
    };
  },
  mounted() {
  },
};
</script>

<style lang="less" scoped>

.wrapper {
  overflow: hidden;
  width: 100%;
  text-align: center;

  cursor: move;
  .swiper-item {
    width: 100%;
    height: 100%;
    .swiper-img {
      width: 100%;
      // max-width: 1920px;
      // min-width: 1280px;
      max-height: 500px;
      // height: 100%;
      height: 500px;
    }
  }
}
</style>