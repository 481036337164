<template>
  <div id="app">
    <Nav></Nav>
    <main>
      <keep-alive>
        <router-view />
      </keep-alive>
    </main>
    <!-- <Suspension></Suspension> -->
    <Footer></Footer>
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";
//import Suspension from "@/components/Suspension.vue"
export default {
  // components: { Nav, Footer,Suspension },
  components: { Nav, Footer },
  data() {
    return {};
  },
};
</script>

<style lang="less">
html {
  position: relative;
  height: 100%;
}

body {
  margin: 0;
}

#app {
  font-family: "微软雅黑";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  //min-width: 1560px;
  min-height: 100%;
  width: 100%;
  position: absolute;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
}
</style>
