<template>
  <footer>
   <!-- <div class="glorys"> -->
      <!-- <div v-for="(item, idx) in arry" :key="idx" class="glory">
        <div class="img">
          <img :src="item.ImagePath" />
        </div>
        <div>
          <p v-text="item.Name"></p>
          <p v-text="item.EnCode"></p>
        </div>
      </div> -->
    <!-- </div>  -->
    <div class ="db">公司总部地址：{{ baseInfo.FullAddress }}</div>
    <div>
      <span
        >Copyright © 2013-2021 www.ngrsz.com
        苏州尼歌尔建材有限公司 版权所有</span
      >
      <a href="https://beian.miit.gov.cn" class="tiaozhuan">
        {{ baseInfo.WebsiteRecordNumber }}</a
      >
    </div>
  </footer>
</template>

<script>
//import { get } from "../plugins/axios";
export default {
  data() {
    return {
      // arry: [
      //   {
      //     Name: "国家高新技术企业",
      //     EnCode: "GR201832004824",
      //     ImagePath: "../assets/tp/logo.png",
      //     //'../assets/微信.png'
      //   },
      // ],
      baseInfo: {
        FullAddress: "江苏省苏州市苏州工业园区和顺路58号",
        WebsiteRecordNumber: "苏ICP备2022031945号",
      },
    };
  },

  mounted() {
    // this.$nextTick(function () {
    //   get("/HomeApi/QueryFooterInfo")
    //     .then((data) => {
    //       console.log(data);
    //       this.arry = data.certifications;
    //      // this.baseInfo = data.baseInfo;
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     });
    // });
  },
};
</script>

<style lang="less" scoped>

footer {
  background: #333;
  color: #fff;
  height: 100px;
  display: grid;
  grid-template-rows: 3fr 1fr 1fr;
  justify-content: center;
  text-align: center;
  .tiaozhuan {
    color: #fff;
    text-decoration: none;
  }
    .db {
      
       margin-top: 15px;
    }
  .glorys {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    .glory {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .glory + .glory {
      margin-left: 20px;
    }
    .img {
      //border: 1px #fff solid;
      margin-right: 8px;
      margin-top: 5px;
      img {
        width: 55px;
        height: 55px;
      }
    }
  }
}
</style>