<template>
    <div class="tt">
        <div class="index-roll">
            <vue-seamless-scroll :data="partnerList" :class-option="optionLeft">
                <ul v-bind:style="{ 'width': (partnerList.length * 200) + 'px' }">
                    <li v-for="(item, index) in partnerList" :key="index">
                        <img :src="item.imgUrl">
                        <!-- <div class="image-div">
                            <img v-if="item.imgUrl" :src="item.imgUrl" :alt="item.name" />
                        </div> -->
                        <div class="text-div">{{ item.name }}</div>
                    </li>
                </ul>
            </vue-seamless-scroll>
        </div>
    </div>
</template>

<script>
export default {
   data() {
       return {
           partnerList: [
               {
                   id: "1",
                   name:"生态木",
                   imgUrl:
                       "../assets/YPSC/stm/stm1.jpg",
               },
               {
                   id: "2",
                   name: "实木长城板格栅",
                   imgUrl:
                       "../assets/YPSC/stm/stm2.jpg",
               },
               {
                   id: "3",
                   name: "金属覆膜板",
                   imgUrl:
                       "../assets/YPSC/jsfgm/jsfgm1.jpg",
               },
               {
                   id: "4",
                   name: "金属覆膜板1",
                   imgUrl:
                       "../assets/YPSC/jsfgm/jsfgm2.jpg",
               },
               {
                   id: "5",
                   name: "软硬包",
                   imgUrl:
                       "../assets/YPSC/ryb/ryb1.jpg",
               },
               {
                   id: "6",
                   name: "吸音软包",
                   imgUrl:
                       "../assets/YPSC/ryb/ryb2.jpg",
               },
               {
                   id: "7",
                   name: "吸音软包2",
                   imgUrl:
                       "../assets/YPSC/ryb/ryb3.jpg",
               },
               {
                   id: "8",
                   name: "玻镁吸音板",
                   imgUrl:
                       "../assets/YPSC/xyb/xyb1.jpg",
               },
               {
                   id: "9",
                   name: "玻纤吸音板",
                   imgUrl:
                       "../assets/YPSC/xyb/xyb2.jpg",
               },
               {
                   id: "10",
                   name: "穿孔吸音板",
                   imgUrl:
                       "../assets/YPSC/xyb/xyb3.jpg",
               },
               {
                   id: "11",
                   name: "方格吸音板",
                   imgUrl:
                       "../assets/YPSC/xyb/xyb4.jpg",
               },
               {
                   id: "12",
                   name: "红芯吸音板",
                   imgUrl:
                       "../assets/YPSC/xyb/xyb5.jpg",
               },
               {
                   id: "13",
                   name: "聚酯纤维吸音板",
                   imgUrl:
                       "../assets/YPSC/xyb/xyb6.jpg",
               },
               {
                   id: "14",
                   name: "木丝吸音板",
                   imgUrl:
                       "../assets/YPSC/xyb/xyb7.jpg",
               },
               {
                   id: "15",
                   name: "砂岩无缝吸音板",
                   imgUrl:
                       "../assets/YPSC/xyb/xyb8.jpg",
               },
               {
                   id: "16",
                   name: "陶铝吸音板",
                   imgUrl:
                       "../assets/YPSC/xyb/xyb9.jpg",
               },
               {
                   id: "17",
                   name: "条形孔吸音板",
                   imgUrl:
                       "../assets/YPSC/xyb/xyb10.jpg",
               },
               {
                   id: "18",
                   name: "条形音板",
                   imgUrl:
                       "../assets/YPSC/xyb/xyb11.jpg",
               },
               {
                   id: "19",
                   name: "吸音扩散板",
                   imgUrl:
                       "../assets/YPSC/xyb/xyb12.jpg",
               },
               {
                   id: "20",
                   name: "定制大转角",
                   imgUrl:
                       "../assets/YPSC/ylb/ylb1.jpg",
               },
               {
                   id: "21",
                   name: "医疗板转角",
                   imgUrl:
                       "../assets/YPSC/ylb/ylb2.jpg",
               },
               {
                   id: "22",
                   name: "医疗洁净板 防火板饰面",
                   imgUrl:
                       "../assets/YPSC/ylb/ylb3.jpg",
               },
               {
                   id: "23",
                   name: "医疗洁净板",
                   imgUrl:
                       "../assets/YPSC/ylb/ylb4.jpg",
               },
               {
                   id: "24",
                   name: "医疗洁净板三聚氰胺面",
                   imgUrl:
                       "../assets/YPSC/ylb/ylb5.jpg",
               },
           ],
       }
   },
   computed: {
       optionLeft() {
           return {
               step: 1, // 数值越大速度滚动越快
               limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
               hoverStop: true, // 是否开启鼠标悬停stop
               direction: 2, // 0向下 1向上 2向左 3向右
               openWatch: true, // 开启数据实时监控刷新dom
               singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
               singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
               waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
           }
       }
   },
   methods: {
    //    getPartnerList() {
    //        this.$http.get('/cms/cmsurl/getListByModuleType', {
    //            params: {
    //                moduleType: '1462717761928450049'
    //            }
    //        }).then(({ data: res }) => {
    //            if (res.code !== 0) {
    //                this.partnerList = []
    //                this.total = 0
    //                return this.$message.error(res.msg)
    //            }
    //            this.partnerList = res.data
    //        }).catch(() => {
    //        })
    //    }
   }
}
</script>

 <style lang="less">
 
  
  
       .tt {
        width: 98%;
        margin-left: 1%;
       margin-bottom: 40px;
       }
       .index-roll {
           overflow: hidden;
           height: 130px;
           // width: 1140px;
            width: 90%;
           margin: 0 auto 0px auto;
           ul {
               display: flex;
               li {
                   width: 200px;
                  //  margin: 0 10px;
                   padding: 10px 10px;
                   background-color: #f8f8f8;
                   border-radius: 4px;
               }
               li:hover{
                   cursor: pointer;
                   border-bottom: 0px solid #43aaed;
               }
               img{
                   width: auto;
                   height: 80px;
                  // width: 100px;
                  // height: auto;
                   display: block;
                   margin: 0 auto;
               }
                                             
           }
       }
       
           .text-div {
               text-align: center;
               color: #333;
               font-size: 14px;
           }
  
 </style> 