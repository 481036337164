import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/cs',
    name: 'CS',
    component: () => import('../views/CSS.vue')
  },
  {
    path: "/soulution",
    name: "Soulution",
    component: () => import("@/views/Solution.vue")
  },
  {
    path: "/faq",
    component: () => import("@/views/Faq.vue"),
    children: [{
      path: "",
      name: "faq-all",
      meta: {
        tag: "faq"
      },
      component: () => import("@/components/faq/FaqContent.vue")
    },
    {
      path: "news",
      name: "faq-news",
      meta: {
        tag: "faq"
      },
      component: () => import("@/components/faq/FaqContent.vue")
    }, {
      path: "trends",
      name: "faq-trends",
      meta: {
        tag: "faq"
      },
      component: () => import("@/components/faq/FaqContent.vue")
    }, {
      path: "report",
      name: "faq-report",
      meta: {
        tag: "faq"
      },
      component: () => import("@/components/faq/FaqContent.vue")
    }
    ]
  },
  {
    path: "/cooperation",
    name: "Cooperation",
    component: () => import("@/views/Cooperation.vue")
  },
  {
    path: "/customer",
    name: "Customer",
    component: () => import("@/views/Customer.vue")
  },
  {
    path: "/production",
    // name: "Production",
    component: () => import("@/views/Production.vue"),
    children: [
      {
        path: "",
        name: "all",
        meta: {
          tag: "production"
        },
        component: () => import("@/components/production/ProductionContents.vue")
      },
      {
        path: "school",
        name: "school",
        meta: {
          tag: "production"
        },
        component: () => import("@/components/production/ProductionContents.vue")
      }, {
        path: "hospital",
        name: "hospital",
        meta: {
          tag: "production"
        },
        component: () => import("@/components/production/ProductionContents.vue")
      }, {
        path: "Grand-Theatre",
        name: "Grand-Theatre",
        meta: {
          tag: "production"
        },
        component: () => import("@/components/production/ProductionContents.vue")
      }
    ]
  },
  {
    path: "/solution",
    name: "Solution",
    component: () => import("@/views/Solution.vue")
  },
  {
    path: "/faq/detail/:id",
    name: "FaqDetail",
    meta: {
      tag: "faq"
    },
    component: () => import("@/views/FaqDetail.vue")
  }, {
    path: "/solution/detail/:id",
    name: "SolutionDetail",
    meta: {
      tag: "solution"
    },
    component: () => import("@/views/SolutionDetail.vue")
  },
  {
    path: "/production/detail/:id",
    name: "ProductionDetail",
    meta: {
      tag: "production"
    },
    component: () => import("@/views/ProductionDetail.vue")
  },
  {
    path: "*",
    name: "404",
    component: () => import("@/views/404.vue")
  }
]



const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router