<template>
  <div>
    <banner></banner>
    <div class="home">

      <split-title :title="tabs.filter((f) => f.EnCode === 'ZXZX')[0].Name"
        :desc="tabs.filter((f) => f.EnCode === 'ZXZX')[0].Attr3"></split-title>
      <CS></CS>
      <split-title :title="tabs.filter((f) => f.EnCode === 'CPZX')[0].Name"
        :desc="tabs.filter((f) => f.EnCode === 'CPZX')[0].Attr3"></split-title>
      <div class="prop-wrap">
        <production-card v-for="(item, idx) in prodCards" :key="idx" :title="item.Name" :desc="item.ProductDescription"
          :imgSrc="item.ImagePath" :id="item.Id"></production-card>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "../components/home/Banner.vue";
import SplitTitle from "../components/home/SplitTitle.vue";
import ProductionCard from "../components/production/ProductionCard.vue";
import CS from "../views/CSS.vue";
export default {
  components: { Banner, SplitTitle, ProductionCard,CS },

  data() {
    return {
      
      chartOptions: {},
      prodCards: [
        {
          Name: "大剧院系列",
          ProductDescription:
            "苏州湾大剧院",
          ImagePath: "../assets/DJY/szwdjy1.jpg",
          Id: "szwdjy",
        },
        {
          Name: "学校系列",
          ProductDescription:
            "奉贤区老年大学",
          ImagePath: "../assets/XX/shlndx1.jpg",
          Id: "shlndx",
        },

        {
          Name: "医院系列",
          ProductDescription:
            "南京市公共卫生中心",
          ImagePath: "../assets/YY/njsggwszx1.jpg",
          Id: "njsggwszx",
        },
        {
          Name: "大剧院系列",
          ProductDescription:
            "澳大利亚The Wharf的剧院",
          ImagePath: "../assets/DJY/adly1.jpg",
          Id: "adly",
        },

      ],
      series: [],
      tabs: [
        {
          Name: "经典案例",
          Attr3: "",
          EnCode: "CPZX",
        },

        {
          Name: "产品展示",
          Attr3: "",
          EnCode: "ZXZX",
        },
      ],
    
    };
  },
  mounted() {
  },
};
</script>

<style lang="less" scoped>

.home {
  width: 100%;
  margin: 0 auto;
}
.split-title {
  margin-top: 10px;
  margin-bottom: -30px;
}

.card-wrap {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(
    3,
    minmax(350px, 33.33%)
  );
  padding: 0px 20px;
  grid-gap: 20px;

  justify-items: center;
  justify-content: center;

  .sol-card {
    width: 100%;
    height: 500px;
  }

  .prod-card {
    width: 100%;
    height: 400px;
  }
}

.prop-wrap {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, minmax(200px, 50%));
  padding: 30px 20px;
  grid-gap: 20px;

  .prod-card {
    min-width: 300px;
    height: 420px;
  }
}

.faq-wrap {
  margin: 0 auto;
  display: flex;
  //padding: 30px 20px;
  justify-items: center;
  justify-content: center;
  .prod-card {
    min-width: 300px;
    height: 400px;
  }
}
</style>
